/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  getCountryCallingCode,
  isPossiblePhoneNumber,
  Country,
} from 'react-phone-number-input';
import Box from '@mui/material/Box';
import { ServiceFormValues } from '../../../interfaces';
import useSignIn from '../../../hooks/global/useSignIn';
import AuthCode from '../../auth/AuthCode';
import ResendCode from '../../auth/ResendCode';
import useUser from '../../../hooks/global/useUser';
import PageLayout from '../../Global/PageLayout';
import BirthdayInput from '../base/BirthdayInput';
import { LoginSteps } from '../../../constants/global';
import { ConfirmationResult } from '../../../types/auth';
import useVerifyPhoneNumber from '../../../hooks/global/useVerifyPhoneNumber';

type Props = {
  nextStep: () => void;
  prevStep?: () => void;
};

function PersonalInfo({ nextStep, prevStep }: Props) {
  const formik = useFormikContext<ServiceFormValues>();
  const { user } = useUser();

  const [phoneNumber, setPhoneNumber] = React.useState<string>();
  const [selectedCountry, setSelectedCountry] = React.useState<Country>('US');
  const [code, setCode] = React.useState<string>('');
  const [loginStep, setLoginStep] = React.useState<LoginSteps>(
    LoginSteps.Phone,
  );
  const [confirmationResult, setConfirmationResult] =
    React.useState<ConfirmationResult | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const { isPending, checkVerificationMutation } = useVerifyPhoneNumber({
    onCheckSuccess: isValid => {
      if (!isValid) return;
      nextStep();
    },
  });
  const signInMutation = useSignIn({
    onSuccess: response => {
      if ('error' in response) {
        setErrorMessage(response.error);
        return;
      }
      setConfirmationResult({
        confirmationType: 'firebase',
        ...response.confirmation,
      });
      setLoginStep(LoginSteps.Verify);
    },
  });

  const validPhoneNumber = phoneNumber && isPossiblePhoneNumber(phoneNumber);
  const codeValid = code.length === 6;
  const isOnVerifyStep = loginStep === LoginSteps.Verify;

  const sendCode = React.useCallback(async () => {
    if (phoneNumber) {
      signInMutation.mutate(phoneNumber);
    }
  }, [phoneNumber, signInMutation]);

  const authenticate = React.useCallback(async () => {
    if (!confirmationResult) return;
    checkVerificationMutation.mutate({
      phoneNumber: phoneNumber!,
      code,
      confirmation: confirmationResult,
    });
  }, [confirmationResult, checkVerificationMutation, phoneNumber, code]);

  const handleNext = isOnVerifyStep ? authenticate : user ? nextStep : sendCode;
  const handleBack = isOnVerifyStep ? undefined : prevStep;

  const formIsValid =
    formik.values.firstName &&
    formik.values.lastName &&
    formik.values.email &&
    formik.values.dob;

  const primaryDisabled = isOnVerifyStep
    ? !codeValid || isPending
    : user
    ? !formIsValid
    : !formIsValid || signInMutation.isPending || !validPhoneNumber;

  return (
    <PageLayout
      onBack={handleBack}
      onNext={handleNext}
      header={isOnVerifyStep ? "What's the code?" : 'Enter your information'}
      subheader={isOnVerifyStep ? `Enter the code sent to ${phoneNumber}` : ''}
      nextDisabled={primaryDisabled}>
      {isOnVerifyStep ? (
        <Box className="verify-code">
          <Box className="input-container no-gap">
            <AuthCode value={code} onChange={setCode} />
            <ResendCode
              phone={phoneNumber}
              onSuccess={response => setConfirmationResult(response)}
            />
          </Box>
          {checkVerificationMutation.isError && (
            <Typography variant="h3" color="primary" className="error">
              Code could not be verified. Please try again
            </Typography>
          )}
        </Box>
      ) : (
        <Box className="multi-input-container" style={{ padding: '0px 16px' }}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
          <TextField
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {!user && (
            <Box className="phone-input-container">
              <span className="country-code">
                +{getCountryCallingCode(selectedCountry)}
              </span>
              <PhoneInput
                defaultCountry="US"
                value={phoneNumber}
                onChange={setPhoneNumber}
                // @ts-ignore
                onCountryChange={setSelectedCountry}
                countrySelectProps={{ unicodeFlags: true }}
                placeholder="Phone number"
              />
            </Box>
          )}
          <BirthdayInput label="Birthday" name="dob" useTextField />
        </Box>
      )}
      {formik.errors.dob && formik.touched.dob && (
        <Typography variant="body1" className="error">
          {formik.errors.dob}
        </Typography>
      )}
      {formik.errors.email && formik.touched.email && (
        <Typography variant="body1" className="error">
          {formik.errors.email}
        </Typography>
      )}
      {errorMessage && (
        <Typography variant="body1" color="primary" className="error">
          {errorMessage}
        </Typography>
      )}
    </PageLayout>
  );
}

export default PersonalInfo;
